<template>
  <div
    id="kt_header_mobile"
    class="kt-header-mobile"
    v-bind:class="headerClasses"
  >
    <div class="kt-header-mobile__logo poschodoch-mobile-header">
      <span class="poschodoch-mobile-logo">
        <router-link to="/">
          <b-img
            class="poschodoch-mobile-logo"
            fluid
            alt="Poschodoch logo"
            :src="headerLogo"
          ></b-img>
        </router-link>
      </span>
    </div>
    <div class="kt-header-mobile__toolbar">
      <button
        class="kt-header-mobile__topbar-toggler"
        id="kt_header_mobile_topbar_toggler"
        ref="kt_header_mobile_topbar_toggler"
      >
        <i class="flaticon-more"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTToggle from "@/assets/js/toggle.js";

export default {
  name: "KTHeaderMobileLogin",
  components: {},
  mounted() {
    new KTToggle(this.$refs["kt_header_mobile_topbar_toggler"], {
      target: "body",
      targetState: "kt-header__topbar--mobile-on",
      togglerState: "kt-header-mobile__toolbar-topbar-toggler--active",
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      if (window.location.host.includes("enbra")) {
        return (
          process.env.BASE_URL +
          this.layoutConfig("self.logo-enbra.poschodoch-min")
        );
      }
      if (window.location.host.includes("poschodech")) {
        return (
          process.env.BASE_URL +
          this.layoutConfig("self.logo-cz.poschodoch-min")
        );
      }
      if (window.location.host.includes("penta")) {
        return (
          process.env.BASE_URL +
          this.layoutConfig("self.logo-penta.poschodoch-min")
        );
      }
      return (
        process.env.BASE_URL + this.layoutConfig("self.logo.poschodoch-min")
      );
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
