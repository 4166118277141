<template>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <KTHeaderMobileLogin></KTHeaderMobileLogin>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
      id="kt_wrapper"
    >
      <KTHeaderLogin></KTHeaderLogin>
      <div
        class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        id="kt_content"
      >
        <div
          class="kt-container kt-grid__item kt-grid__item--fluid"
          style="
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <b-container fluid="sm">
            <b-row>
              <b-col></b-col>
              <router-view></router-view>
              <b-col></b-col>
            </b-row>
          </b-container>
        </div>
      </div>
      <KTFooterLogin></KTFooterLogin>
    </div>
    <KTQuickPanel></KTQuickPanel>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import KTAside from "@/views/theme/aside/Aside.vue";
import KTHeaderLogin from "@/views/theme/header/HeaderLogin.vue";
import KTHeaderMobileLogin from "@/views/theme/header/HeaderMobileLogin.vue";
import KTFooterLogin from "@/views/theme/footer/FooterLogin.vue";
import HtmlClass from "@/common/htmlclass.service";
//import KTSubheader from "@/views/theme/subheader/Subheader.vue";
//import KTStickyToolbar from "@/views/partials/layout/StickyToolbar.vue";
import KTQuickPanel from "@/views/partials/layout/QuickPanel.vue";
import KTScrollTop from "@/views/partials/layout/ScrollTop";
import Loader from "@/views/partials/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/store/htmlclass.module.js";

export default {
  name: "Base",
  components: {
    //KTAside,
    KTHeaderLogin,
    KTHeaderMobileLogin,
    KTFooterLogin,
    //KTSubheader,
    //KTStickyToolbar,
    KTQuickPanel,
    KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");

    // initialize html element classes
    HtmlClass.init();

    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-aside--enabled");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-aside--fixed");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-aside--minimize");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "login-background");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "login-background-enbra");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "login-background-penta");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-subheader--fixed");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-subheader--enabled");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-subheader--solid");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-brand-light");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-brand-dark");
    this.setRandomBackground();
  },
  mounted() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
  },
  methods: {
    setRandomBackground() {
      let backgroundId = Math.floor(Math.random() * 3) + 1;

      if (window.location.host.includes("enbra")) {
        this.$store.dispatch(
          ADD_BODY_CLASSNAME,
          "login-background-enbra-" + backgroundId,
        );
      } else if (window.location.host.includes("penta")) {
        this.$store.dispatch(
          ADD_BODY_CLASSNAME,
          "login-background-penta-" + backgroundId,
        );
      } else {
        this.$store.dispatch(
          ADD_BODY_CLASSNAME,
          "login-background-" + backgroundId,
        );
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page laoder is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !!this.layoutConfig("loader.enabled");
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      if (window.location.host.includes("enbra")) {
        return process.env.BASE_URL + this.layoutConfig("loader.logo-enbra");
      }
      if (window.location.host.includes("penta")) {
        return process.env.BASE_URL + this.layoutConfig("loader.logo-penta");
      }

      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
