<template>
  <div
    class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop poschodoch-login-footer"
    id="kt_footer"
    v-if="!isAuthenticated"
  >
    <div
      class="kt-container"
      v-bind:class="{ 'kt-container--fluid': isFluid() }"
      v-if="poschodoch() && !enbra()"
    >
      <div class="poschodoch-foter-left">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN.LEFT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN.LEFT_2") }}</div>
        <div>
          <span class="mr-2">{{ $t("LAYOUT.FOOTER_LOGIN.LEFT_3") }}</span>
          <a
            class="kt-link kt-link--dark mr-2"
            href="https://www.anasoft.com/sk/"
          >
            www.anasoft.com
          </a>
          <a class="kt-link kt-link--dark mr-2" href="mailto:domus@anasoft.com">
            domus@anasoft.com
          </a>
        </div>
        <div>
          <router-link
            :to="{ name: 'registrationTerms', query: { type: 2 } }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.LEGAL_A") }}
          </router-link>
        </div>
      </div>
      <div class="poschodoch-foter-right">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN.RIGHT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN.RIGHT_2") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN.RIGHT_3") }}</div>
        <div>
          <router-link
            :to="{ name: 'gdprTerms' }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.PRIVACY") }}
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="kt-container"
      v-bind:class="{ 'kt-container--fluid': isFluid() }"
      v-if="poschodech() && !enbra()"
    >
      <div class="poschodoch-foter-left">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_CZ.LEFT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_CZ.LEFT_2") }}</div>
        <div>
          <span class="mr-2">{{ $t("LAYOUT.FOOTER_LOGIN_CZ.LEFT_3") }}</span>
          <a
            class="kt-link kt-link--dark mr-2"
            href="https://www.anasoft.com/cz/ "
          >
            www.anasoft.com
          </a>
          <a class="kt-link kt-link--dark mr-2" href="mailto:domus@anasoft.com">
            domus@anasoft.com
          </a>
        </div>
        <div>
          <router-link
            :to="{ name: 'registrationTerms', query: { type: 2 } }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.LEGAL_A") }}
          </router-link>
        </div>
      </div>
      <div class="poschodoch-foter-right">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_CZ.RIGHT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_CZ.RIGHT_2") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_CZ.RIGHT_3") }}</div>
        <div>
          <router-link
            :to="{ name: 'gdprTerms' }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.PRIVACY") }}
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="kt-container"
      v-bind:class="{ 'kt-container--fluid': isFluid() }"
      v-if="poschodoch() && enbra()"
    >
      <div class="poschodoch-foter-left">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA.LEFT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA.LEFT_2") }}</div>
        <div>
          <span class="mr-2">{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA.LEFT_3") }}</span>
          <a class="kt-link kt-link--dark mr-2" href="http://www.enbra.sk">
            www.enbra.sk
          </a>
          <a class="kt-link kt-link--dark mr-2" href="mailto:enbra@enbra.sk">
            enbra@enbra.sk
          </a>
        </div>
        <div>
          <router-link
            :to="{ name: 'registrationTerms', query: { type: 4 } }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.LEGAL_Z") }}
          </router-link>
          <a class="kt-link kt-link--dark mr-2"></a>
          <router-link
            :to="{ name: 'registrationTerms', query: { type: 2 } }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.LEGAL_A") }}
          </router-link>
        </div>
      </div>
      <div class="poschodoch-foter-right">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA.RIGHT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA.RIGHT_2") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA.RIGHT_3") }}</div>
        <div>
          <router-link
            :to="{ name: 'gdprTerms' }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.PRIVACY") }}
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="kt-container"
      v-bind:class="{ 'kt-container--fluid': isFluid() }"
      v-if="poschodech() && enbra()"
    >
      <div class="poschodoch-foter-left">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA_CZ.LEFT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA_CZ.LEFT_2") }}</div>
        <div>
          <span class="mr-2">
            {{ $t("LAYOUT.FOOTER_LOGIN_ENBRA_CZ.LEFT_3") }}
          </span>
          <a class="kt-link kt-link--dark mr-2" href="http://www.enbra.cz">
            www.enbra.cz
          </a>
          <a class="kt-link kt-link--dark mr-2" href="mailto:enbra@enbra.cz">
            enbra@enbra.cz
          </a>
        </div>
        <div>
          <router-link
            :to="{ name: 'registrationTerms', query: { type: 5 } }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.LEGAL_Z") }}
          </router-link>
          <a class="kt-link kt-link--dark mr-2"></a>
          <router-link
            :to="{ name: 'registrationTerms', query: { type: 2 } }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.LEGAL_A") }}
          </router-link>
        </div>
      </div>
      <div class="poschodoch-foter-right">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA_CZ.RIGHT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA_CZ.RIGHT_2") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_ENBRA_CZ.RIGHT_3") }}</div>
        <div>
          <router-link
            :to="{ name: 'gdprTerms' }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.PRIVACY") }}
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="kt-container"
      v-bind:class="{ 'kt-container--fluid': isFluid() }"
      v-if="penta()"
    >
      <div class="poschodoch-foter-left">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_PENTA.LEFT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_PENTA.LEFT_2") }}</div>
        <div>
          <span class="mr-2">
            {{ $t("LAYOUT.FOOTER_LOGIN_PENTA.LEFT_3") }}
          </span>
          <a
            class="kt-link kt-link--dark mr-2"
            href="https://www.pentaservices.sk"
          >
            www.pentaservices.sk
          </a>
          <a
            class="kt-link kt-link--dark mr-2"
            href="mailto:bratislava@pentaservices.sk"
          >
            bratislava@pentaservices.sk
          </a>
        </div>
        <div>
          <router-link
            :to="{ name: 'registrationTerms', query: { type: 6 } }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.LEGAL_Z") }}
          </router-link>
          <a class="kt-link kt-link--dark mr-2"></a>
          <router-link
            :to="{ name: 'registrationTerms', query: { type: 2 } }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.LEGAL_A") }}
          </router-link>
        </div>
      </div>
      <div class="poschodoch-foter-right">
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_PENTA.RIGHT_1") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_PENTA.RIGHT_2") }}</div>
        <div>{{ $t("LAYOUT.FOOTER_LOGIN_PENTA.RIGHT_3") }}</div>
        <div>
          <router-link
            :to="{ name: 'gdprTerms' }"
            class="kt-link kt-link--dark"
            target="_blank"
          >
            {{ $i18n.t("LAYOUT.FOOTER.PRIVACY") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div
    class="no-print kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop poschodoch-footer"
    id="kt_footer"
    v-else
  >
    <div
      class="kt-container"
      v-bind:class="{ 'kt-container--fluid': isFluid() }"
    >
      <div class="kt-footer__copyright">
        <router-link
          :to="{ name: 'registrationTerms', query: { type: 2 } }"
          class="kt-link mr-3"
          target="_blank"
        >
          {{ $i18n.t("LAYOUT.FOOTER.LEGAL_A") }}
        </router-link>
        <!--<router-link
          :to="{ name: 'registrationTerms', query: { type: 3 } }"
          class="kt-link mr-3"
          target="_blank"
        >
          {{ $i18n.t("LAYOUT.FOOTER.LEGAL_Z") }}
        </router-link>-->
        <router-link
          :to="{ name: 'gdprTerms' }"
          class="kt-link mr-3"
          target="_blank"
        >
          {{ $i18n.t("LAYOUT.FOOTER.PRIVACY") }}
        </router-link>
      </div>
      <div class="kt-footer__menu">Domus &nbsp;&copy;&nbsp; {{ year }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooterLogin",
  data() {
    return {
      year: new Date().getUTCFullYear(),
    };
  },
  methods: {
    poschodoch() {
      if (
        (window.location.host.includes("poschodoch") ||
          window.location.host.includes("mojbyt")) &&
        !window.location.host.includes("penta")
      )
        return true;
      else return false;
    },
    poschodech() {
      if (
        (window.location.host.includes("poschodech") ||
          window.location.host.includes("mujbyt")) &&
        !window.location.host.includes("penta")
      )
        return true;
      else return false;
    },
    enbra() {
      if (window.location.host.includes("enbra")) return true;
      else return false;
    },
    penta() {
      if (window.location.host.includes("penta")) return true;
      else return false;
    },
    /**
     * Check if footer container is fluid
     * @returns {boolean}
     */
    isFluid() {
      return this.layoutConfig("footer.self.width") === "fluid";
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "isAuthenticated"]),
  },
};
</script>
