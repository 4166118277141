<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <!--begin: Language bar -->
    <div
      class="kt-header__topbar-item kt-header__topbar-item--langs poschodoch-language"
    >
      <div
        class="kt-header__topbar-wrapper"
        id="kt_language_toggle"
        data-toggle="dropdown"
      >
        <span class="kt-header__topbar-icon">
          {{ language }}
          <i class="la la-angle-down"></i>
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownLanguage
          v-on:language-changed="onLanguageChanged"
        ></KTDropdownLanguage>
      </div>
    </div>
    <!--end: Language bar -->

    <!--begin: Sign in if not Logged in-->
    <div
      class="kt-header__topbar-item kt-header__topbar-item--user poschodoch-login-link"
    >
      <div class="kt-header__topbar-user">
        <router-link
          class="kt-link kt-link--dark kt-login__signin-link text-decoration-none"
          :to="{ name: 'login' }"
        >
          {{ $t("AUTH.GENERAL.SIGNIN_BUTTON") }}
        </router-link>
      </div>
    </div>
    <!--end: Sign in -->

    <!--begin: Sign up if not Logged in-->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div class="kt-header__topbar-user">
        <router-link
          class="kt-link kt-link--dark kt-login__signup-link text-decoration-none"
          :to="{ name: 'registrationCode' }"
        >
          {{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}
        </router-link>
      </div>
    </div>
    <!--end: Sign up -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTDropdownLanguage from "@/views/theme/topbar/DropdownLanguage.vue";
import i18nService from "@/common/i18n.service.js";
import JwtService from "@/common/jwt.service";

export default {
  name: "KTTopbarLogin",
  data() {
    return {
      language: i18nService.getActiveLanguage().toUpperCase(),
      languages: i18nService.languages,
      username: JwtService.getUsername(),
    };
  },
  components: {
    KTDropdownLanguage,
  },
  methods: {
    onLanguageChanged() {
      this.language = this.languages
        .find((val) => {
          return val.lang === i18nService.getActiveLanguage();
        })
        .lang.toUpperCase();
    },
  },
};
</script>
